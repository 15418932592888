// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beers-js": () => import("./../../../src/pages/beers.js" /* webpackChunkName: "component---src-pages-beers-js" */),
  "component---src-pages-brewery-js": () => import("./../../../src/pages/brewery.js" /* webpackChunkName: "component---src-pages-brewery-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-seltzers-js": () => import("./../../../src/pages/seltzers.js" /* webpackChunkName: "component---src-pages-seltzers-js" */),
  "component---src-pages-taproom-js": () => import("./../../../src/pages/taproom.js" /* webpackChunkName: "component---src-pages-taproom-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-templates-beer-js": () => import("./../../../src/templates/beer.js" /* webpackChunkName: "component---src-templates-beer-js" */),
  "component---src-templates-seltzer-js": () => import("./../../../src/templates/seltzer.js" /* webpackChunkName: "component---src-templates-seltzer-js" */)
}

